.logo {
  max-width: 250px;
  margin-top: 60px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.paper {
  padding: 20px 30px;
  margin-top: 60px;
}
