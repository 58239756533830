.classificationWrapper {
  display: flex;
  padding: 20px;
  flex-direction: column;
}

.productInput {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.classification {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 80%;
  z-index: 3;
}

.dialogBackground {
  background: #0000007d;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.hsCode {
  align-self: center;
  width: 300px;
}

.logo {
  max-width: 250px;
  margin-top: 100px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
